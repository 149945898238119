import chunk from 'lodash/chunk';
import pluralize from 'pluralize';

import {
  type DtoGamePack,
  type DtoSharedAsset,
  EnumsGamePackAudience,
  EnumsGamePackCompetitionLevel,
  EnumsGamePackInstructionRule,
  EnumsGamePackLeaderboardRule,
  EnumsGamePackMakeUnitsFrom,
  EnumsGamePackVersion,
  type ModelsGamePackPrice,
  type ModelsMarkAsNew,
  type ModelsPlaybackSettings,
} from '@lp-lib/api-service-client/public';
import { assertExhaustive, type Block } from '@lp-lib/game';

import placeholder from '../../../assets/img/placeholder/game-cover.png';
import { TagUtils } from '../../../types';
import { type GamePack, type GamePackShowcaseCard } from '../../../types/game';
import { snakeCase2titleCase } from '../../../utils/common';
import { MediaUtils, type PickMediaUrlOptions } from '../../../utils/media';
import { type Option } from '../../common/Utilities';
import { type FAQGroup } from '../../FAQ';
import { type TagOption } from '../../Tagging';
import {
  type BlockPreviewMetadata,
  type GamePackEditorFormData,
} from './types';

export class GamePackEditorUtils {
  static DefaultGamePackFormValues(
    pack: Nullable<GamePack>,
    version = EnumsGamePackVersion.GamePackVersionV1,
    blocks?: Block[],
    faqGroups?: FAQGroup[],
    anonymousFAQGroups?: FAQGroup[],
    showcaseCards?: GamePackShowcaseCard[]
  ): GamePackEditorFormData {
    return {
      name: pack?.name ?? '',
      description: pack?.description ?? '',
      featured: pack?.isFeatured ?? false,
      teamRandomizationSettings: pack?.teamRandomizationSettings ?? null,
      coverMediaId: pack?.cover?.id ?? null,
      replayable: pack?.replayable ?? false,
      playerRange: {
        min: pack?.playerRange.min ?? 2,
        max: pack?.playerRange.max,
      },
      promotionalAssets: pack?.promotionalAssets ?? null,
      version: pack?.version ?? version,
      playbackSettings: pack?.playbackSettings ?? null,
      detailSettings: Object.assign(
        {
          audience: EnumsGamePackAudience.GamePackAudienceUsCentric,
          competitionLevel:
            EnumsGamePackCompetitionLevel.GamePackCompetitionLevelNeutral,
          gameDifficulty: null,
          gameInspiredBy: '',
          gameType: '',
          markAsNew: null,
          richDescription: '',
          availability: null,
        },
        pack?.detailSettings
      ),
      marketingSettings: Object.assign(
        {
          anonymousFAQGroupIds: null,
          calendarInviteMessage: null,
          faqGroupIds: null,
          gameBackground: null,
          gameTrailer: null,
          joyCaptureBackground: null,
          lobbyBackground: null,
          musicPlaylistId: null,
          sharableMarketingMaterials: null,
          showcaseCardIds: null,
          useShowcaseCards: null,
        },
        pack?.marketingSettings
      ),
      extraSettings: Object.assign(
        {
          templateId: null,
          oneTimePurchasePricingTable: [],
          usingDefaultPricingTable: true,
        },
        pack?.extraSettings
      ),
      aiHostSettings: Object.assign(
        {
          voiceId: null,
        },
        pack?.aiHostSettings
      ),
      cohostSettings: Object.assign(
        {
          enabled: false,
        },
        pack?.cohostSettings
      ),
      tags: pack?.tags?.map((t) => t.name) ?? null,
      blocks: blocks ?? [],
      blockRandomizationPreview:
        pack?.playbackSettings?.randomizeUnitOrder ?? false,
      approximateDurationSeconds: pack?.approximateDurationSeconds ?? 5 * 60,
      faqGroups: faqGroups ?? null,
      anonymousFAQGroups: anonymousFAQGroups ?? null,
      showcaseCards: showcaseCards ?? null,
      ugcSettings: Object.assign(
        {
          promptTemplateId: null,
          userDirection: null,
        },
        pack?.ugcSettings
      ),
      assessmentSettings: pack?.assessmentSettings ?? null,
    };
  }

  static MakeOptionsFromEnumValues<T>(values: string[]): Option<T>[] {
    return values.map((v) => ({
      label: snakeCase2titleCase(v),
      value: v as T,
    }));
  }

  static FormatTagOptionMeta(option: TagOption) {
    const count = option.__isNew__
      ? 0
      : TagUtils.getPrimeGamePacksCount(option);
    if (option.__isNew__) {
      return '(Create New)';
    }
    return `(${count} ${pluralize('Game Pack', count)})`;
  }
}

export class GamePackUtils {
  static readonly NewTagExpiredAfter = 4 * 7 * 86400 * 1000;

  static PickCoverImage(pack: GamePack, options?: PickMediaUrlOptions) {
    return MediaUtils.PickMediaUrl(pack.cover, options) || placeholder;
  }

  static MakeMarkAsNew(): ModelsMarkAsNew {
    return { expiredAt: Date.now() + this.NewTagExpiredAfter };
  }
  static ValidMarkAsNew(status: Nullable<ModelsMarkAsNew>): {
    valid: boolean;
    note?: string;
  } {
    if (!status) return { valid: false };
    const msec = status.expiredAt - Date.now();
    if (msec <= 0) {
      return { valid: false };
    }
    const days = Math.ceil(msec / 1000 / 86400);
    return { valid: true, note: `${days} ${pluralize('day', days)} left` };
  }

  private static MakePreviewSections(
    blocks: Block[],
    playbackSettings: Partial<ModelsPlaybackSettings>
  ): Array3D<Block> {
    const makeUnitsFrom = playbackSettings?.makeUnitsFrom;
    const unitsPerSession = playbackSettings?.defaultUnitsPerSession ?? 1;
    switch (makeUnitsFrom) {
      case EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromIndividualBlocks:
        return chunk(
          blocks.map((b) => [b]),
          unitsPerSession
        );
      case EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromConsecutiveBrandBlocks:
        const units: Array2D<Block> = [];
        let unit: Block[] = [];
        let lastBrandId: Nullable<string> = null;
        for (let i = 0; i < blocks.length; i++) {
          const block = blocks[i];
          if (lastBrandId !== block.brandId) {
            unit = [block];
            units.push(unit);
          } else {
            unit.push(block);
          }
          lastBrandId = block.brandId;
        }
        return chunk(units, unitsPerSession);
      case EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack:
      case undefined:
      case null:
        return [[blocks]];
      default:
        assertExhaustive(makeUnitsFrom);
        return [[blocks]];
    }
  }

  static MakePlaybackPreview(
    blocks: Block[],
    playbackSettings: Partial<ModelsPlaybackSettings>
  ) {
    const sections = this.MakePreviewSections(blocks, playbackSettings);
    const blockMetadataMap = new Map<Block['id'], BlockPreviewMetadata>();
    let lastBrandId: Nullable<string> = null;
    for (let i = 0; i < sections.length; i++) {
      const units = sections[i];
      for (let j = 0; j < units.length; j++) {
        const blocks = units[j];
        for (let k = 0; k < blocks.length; k++) {
          const block = blocks[k];
          blockMetadataMap.set(block.id, {
            startOfSection: j === 0 && k === 0,
            endOfSection: j === units.length - 1 && k === blocks.length - 1,
            startOfUnit: k === 0,
            endOfUnit: k === blocks.length - 1,
            startOfBrand: lastBrandId !== block.brandId,
            endOfBrand: block.brandId !== blocks[k + 1]?.brandId,
          });
          lastBrandId = block.brandId;
        }
      }
    }

    // note(2023/12/12): this is a hack to support playing an intro video for ond "events".
    // the playback system will disregard unbranded blocks at the _beginning_ of a gamepack from playback rules,i.e.,
    // we do not add an instruction or leaderboard block before or after.
    const firstBrandedBlockIndex = blocks.findIndex((b) => !!b.brandId);
    const brandedBlocks = blocks.slice(firstBrandedBlockIndex);

    const instructionRules = playbackSettings?.instructionRules;
    const leaderboardRules = playbackSettings?.leaderboardRules;
    for (let i = 0; i < brandedBlocks.length; i++) {
      const block = brandedBlocks[i];
      const metadata = blockMetadataMap.get(block.id) ?? {};
      switch (instructionRules) {
        case EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfSession:
          if (metadata.startOfSection) metadata.showInstructions = true;
          break;
        case EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfEachNewBrand:
          if (metadata.startOfBrand) metadata.showInstructions = true;
          break;
        case EnumsGamePackInstructionRule.GamePackInstructionRuleNeverShow:
        case undefined:
        case null:
          break;
        default:
          assertExhaustive(instructionRules);
          break;
      }
      switch (leaderboardRules) {
        case EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleAfterEveryBlock:
          metadata.showLeaderboards = true;
          break;
        case EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleAfterEveryOtherBlock:
          if (i % 2 === 0) metadata.showLeaderboards = true;
          break;
        case EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEveryGameBrandChange:
          if (block.brandId && metadata.endOfBrand)
            metadata.showLeaderboards = true;
          break;
        case EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEndOfSession:
          if (metadata.endOfSection) metadata.showLeaderboards = true;
          break;
        case EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleNeverShow:
        case undefined:
        case null:
          break;
        default:
          break;
      }
      blockMetadataMap.set(block.id, metadata);
    }
    return { blockMetadataMap, sections };
  }

  static GetPlaytestUrl(pack: GamePack, stats: boolean): string {
    const params = new URLSearchParams();
    params.set('game-on-demand-play-test', 'enabled');
    params.set('game-on-demand-skip-timer-control', 'enabled');
    params.set('game-on-demand-use-play-history', 'disabled');
    params.set('lite-mode-notif-trigger-detractor-score', '999999');
    params.set('debug-tools', stats ? 'collapsed' : 'enabled');
    return `/my-venue/${pack.id}?${params.toString()}`;
  }

  static GetCohostUrl(pack: GamePack, playtest: boolean): string {
    const params = new URLSearchParams();
    if (playtest) {
      params.set('game-on-demand-play-test', 'enabled');
      params.set('game-on-demand-skip-timer-control', 'enabled');
      params.set('game-on-demand-use-play-history', 'disabled');
      params.set('lite-mode-notif-trigger-detractor-score', '999999');
      params.set('debug-tools', 'enabled');
    }
    params.set('cohost', 'enabled');
    params.set('cohost-panel', 'open');
    params.set('cohost-video-mixer', 'enabled');
    return `/my-venue/${pack.id}?${params.toString()}`;
  }

  static GetOneTimePurchaseUrl(pack: GamePack) {
    const current = new URLSearchParams(window.location.search);

    const target = new URLSearchParams();
    target.set('pack-id', pack.id);
    for (const param of ['schedule-call', 'promotion-code']) {
      const value = current.get(param);
      if (value) {
        target.set(param, value);
      }
    }
    return `/checkout?${target.toString()}`;
  }

  static ActivePrices(
    input: DtoGamePack | GamePack | ModelsGamePackPrice[] | null | undefined
  ): ModelsGamePackPrice[] {
    if (!input) return [];
    const prices = Array.isArray(input)
      ? input
      : input.extraSettings?.syntheticOTPPricingTable;
    return (
      prices
        ?.filter((p) => !p.archived)
        .sort((a, b) => a.maxPlayers - b.maxPlayers) ?? []
    );
  }

  static FindOneTimePrice(
    input: DtoGamePack | GamePack | ModelsGamePackPrice[] | null | undefined,
    headcount: number
  ) {
    const priceTable = GamePackUtils.ActivePrices(input);
    return priceTable.find((p) => headcount <= p.maxPlayers);
  }

  static ToShowcaseCard(media: DtoSharedAsset): GamePackShowcaseCard {
    return {
      id: media.id,
      primaryText: media.data?.showcaseCard.primaryText ?? '',
      secondaryText: media.data?.showcaseCard.secondaryText ?? '',
      hint: media.data?.showcaseCard.hint,
      media: media.media,
    };
  }

  static IsCohosted(pack: GamePack | DtoGamePack) {
    return pack.cohostSettings?.enabled ?? false;
  }

  static IsLive(pack: GamePack | DtoGamePack) {
    return this.IsCohosted(pack);
  }
}
